import React from 'react';
import styled from 'styled-components';
import { colours, learnTheme, Spin, Layout } from '@a-cloud-guru/rainbow-ui';
import NProgress from 'nprogress';
import Router from 'next/router';
import * as PropTypes from 'prop-types';

import { Spinner } from '../components/spinner/Spinner';

const { fonts, fontSizes, fontWeights } = learnTheme;

NProgress.configure({ parent: 'body', showSpinner: false });

Router.onRouteChangeStart = () => {
  NProgress.start();
};

Router.onRouteChangeComplete = () => {
  NProgress.done();
};

Router.onRouteChangeError = () => {
  NProgress.done();
};

Spin.setDefaultIndicator(<Spinner colour="#fff" />);

const CommonLayout = props => <StyledLayout>{props.children}</StyledLayout>;

CommonLayout.propTypes = {
  children: PropTypes.node
};

const StyledLayout = styled(Layout)`
  font-size: ${fontSizes.rg};
  font-family: ${fonts.brand}
  color: ${colours.navyDark};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${fontWeights.bold};
    font-family: ${fonts.brand};
  }
`;

export default CommonLayout;

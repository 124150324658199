import { makeBuildOptimizedSrc } from '../image-optimizer';

const logoUrl =
  'https://acloudguru-content-assets-production.s3-accelerate.amazonaws.com/1660798159214-ACG-logo_ACG-OrangeWhite.png';
const logoDesktopTransforms = makeBuildOptimizedSrc({
  width: 200,
  format: 'auto'
});
const logoMobileTransforms = makeBuildOptimizedSrc({
  width: 150,
  format: 'auto'
});

const imagePaths = {
  logoDesktop: logoDesktopTransforms(logoUrl),
  logoMobile: logoMobileTransforms(logoUrl)
};

export { imagePaths };

import React from 'react';
import Head from 'next/head';
import styled from 'styled-components';
import { colours, learnTheme, Button } from '@a-cloud-guru/rainbow-ui';
import Router from 'next/router';

import BlankLayout from '../../layouts/BlankLayout';
import { imagePath } from '../../functions/imagePath';

const { fonts, fontSizes, fontWeights } = learnTheme;

export const NotFound = () => {
  return (
    <>
      <Head>
        <title>Not found</title>
      </Head>
      <StyledLayout showLogo={true}>
        <Container>
          <SadCloud src={imagePath('/images/sadcloud.png')}></SadCloud>

          <Title>Oh no!</Title>
          <Blurb>The page you’re looking for doesn&apos;t exist.</Blurb>

          <Button type="primary" onClick={() => Router.push('/dashboard')}>
            Return to your Dashboard
          </Button>
        </Container>
      </StyledLayout>
    </>
  );
};

const StyledLayout = styled(BlankLayout)`
  background: linear-gradient(${colours.navy500}, ${colours.navy700} 40%);
`;

const Container = styled.div`
  margin-top: 10%;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  font-size: ${fontSizes.rg};
`;

const SadCloud = styled.img`
  width: 300px;
  height: 200px;
  margin-top: 71px;
`;

const Title = styled.div`
  margin-top: 71px;
  color: ${colours.lightGrey100};
  font-family: ${fonts.brand};
  font-size: 2.5em;
  font-weight: ${fontWeights.bold};
`;

const Blurb = styled.div`
  color: ${colours.navy100};
  font-family: ${fonts.sans};
  font-size: 1.1em;
  margin-bottom: 40px;
  margin-top: 8px;
`;
